$default-transition: all 0.3s ease;
$fontFamily: "Poppins", sans-serif;
$fontSpaceMono: "Space Mono", sans-serif;
$fontSizeSmaller: 0.75rem;
$fontSizeSmall: 0.875rem;
$fontSizeDefault: 1rem;
$fontSizeTitle: 1.5rem;
$fontSizeTitleLg: 1.75rem;
$fontSizeLg: 2rem;
$fontWeightMedium: 600;
$fontSizeS: 16px;
$pageMaxWidth: 1320px;

$white: #ffffff;
$black: #000000;
$almostBlack: #171717;
$oldTransparentOrange: rgba(255, 140, 44, 0.1);
$transparentOrange: rgba(254, 100, 70, 0.1);
$lessTransparentOrange: rgba(254, 100, 70, 0.2);
$orangeLight: #fec146;
$orange: #fe6446;
$orangeDark: #e53a1f;
$gray: #acacac;
$darkestGray: #222c39;

$transparentRed: rgba(196, 0, 0, 0.1);
$red: #c40000;
$lightRed: #f72424;
$transparentPink: rgba(253, 24, 124, 0.09);
$pinkLight: #ffcfd5;
$blueishGrayVeryLight: #f7f8f9;
$blueishGrayLight: #e0dee7;
$blueishGray: #777b9d;
$grayAlmostAlmostWhite: #ffffff;
$grayAlmostWhite: #fafafa;
$grayVeryVeryLight: #f8f8f8;
$grayVeryLight: #f2f2f2;
$grayBackground: #f7f7f7;
$grayLight: #dbdbdb;
$grayDark: #909090;
$grayDarker: #6f6f6f;
$lightPurple: #6b5b91;
$blueWeird: #353f4a;
$blueVeryLight: #ecf6fa;
$greenVeryVeryLight: #dff0d8;
$greenVeryLight: #d6e9c6;
$greenLight: #94d053;
$green: #63ad13;
$transparentGreen: rgba(148, 208, 83, 0.1);
$greenDark: #3c763d;
$waterGreen: #03a48c1d;
$waterGreenBorder: #2ca28e;
$darkerWaterGreen: #0aa28c;
$waterGreenText: #06a38c;
$borderGrey: #d8d8d8;
$placeholderFont: #8e8e8e;

$mobileMaxWidth: 599px;
$largeDevicesMinWidth: 600px;
$largeDevicesWidth: 992px;
$tabletMinWidth: 993px;
$tabletMaxWidth: 1024px;
$desktopSmallMinWidth: 1025px;
$desktopSmallMaxWidth: 1439px;
$desktopMinWidth: 1440px;
