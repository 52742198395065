@use "@angular/material" as mat;
@include mat.core();

$primary-palette: (
    50: #fae8e7,
    100: #ffc9bc,
    200: #fea692,
    300: #fe8266,
    400: #fe6546,
    500: #fe4628,
    600: #f34124,
    700: #e53a1f,
    800: #d7331b,
    900: #be2512,
    A100: #fff9c8,
    A200: #fff4a3,
    A400: #ffea65,
    A700: #fec146,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$accent-palette: (
    50: #eff7e5,
    100: #d6ebbf,
    200: #badd96,
    300: #9ed06a,
    400: #88c746,
    500: #72bd1c,
    600: #63ad13,
    700: #4d9904,
    800: #378600,
    900: #006400,
    A100: #caffb1,
    A200: #a7ff7e,
    A400: #84ff4b,
    A700: #73ff31,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$warn-palette: (
    50: #f8e0e0,
    100: #edb3b3,
    200: #e28080,
    300: #d64d4d,
    400: #cd2626,
    500: #c40000,
    600: #be0000,
    700: #b60000,
    800: #af0000,
    900: #a20000,
    A100: #ffcccc,
    A200: #ff9999,
    A400: #ff6666,
    A700: #ff4d4d,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

$my-primary: mat.m2-define-palette($primary-palette);
$my-accent: mat.m2-define-palette($accent-palette);
$my-warn: mat.m2-define-palette($warn-palette);

$my-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $my-primary,
            accent: $my-accent,
            warn: $my-warn,
        ),
    )
);

@include mat.all-component-themes($my-theme);

@import "./variables";
@import "./fontface";
@import "./fontface_extra";

.catappult-background-card {
    display: flex;
    box-shadow: 0 4px 8px $grayLight;
    background: $white;
    border-radius: 12px;
    padding: 32px;
    flex-direction: column;
    max-width: $pageMaxWidth;

    @media (max-width: $mobileMaxWidth) {
        padding: 14px 24px 24px 24px;
        box-shadow: unset;
        border-radius: 24px;
    }
}

.top-8-margin {
    margin-top: 8px !important;
}

.top-24-margin {
    margin-top: 24px !important;
}

.top-38-margin {
    margin-top: 38px !important;
}

.top-24-padding {
    padding-top: 24px !important;
}

.page-full-width {
    padding: unset !important;
}

.bottom-24-margin {
    margin-bottom: 24px;
}

.bottom-24-negative-margin {
    margin-bottom: -24px !important;
}

.align-items-center {
    align-items: center;
}

.mat-mdc-radio-button.mat-primary {
    --mat-mdc-radio-checked-ripple-color: unset;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
a,
li,
td,
label,
th,
span {
    margin: unset;

    &.unset-line-height {
        line-height: unset !important; // remove after delete old h1,h2,h3,... styles
    }

    &.no-margin {
        margin: unset;
    }

    &.small-line-height {
        line-height: 1.2;
    }

    &.one-line-height {
        line-height: 1;
    }

    &.medium-line-height {
        line-height: 1.6 !important;
    }

    &.xxxs {
        font-size: 10px;
        line-height: 1.3;
    }

    &.xxs {
        font-size: 12px !important;
        line-height: 1.3 !important;
    }

    &.xs {
        font-size: 14px;
        line-height: 1.35;

        &.big-line-height {
            line-height: 1.6;
        }

        @media (max-width: $mobileMaxWidth) {
            font-size: 12px;
        }
    }

    &.s {
        font-size: 16px;
        line-height: 1.35;

        @media (max-width: $mobileMaxWidth) {
            font-size: 14px;
        }
    }

    &.sm {
        font-size: 18px;
        line-height: 1.4;

        @media (max-width: $mobileMaxWidth) {
            font-size: 16px;
        }
    }

    &.m {
        font-size: 22px;

        @media (max-width: $mobileMaxWidth) {
            font-size: 16px;
        }
    }

    &.l {
        font-size: 28px;

        @media (max-width: $mobileMaxWidth) {
            font-size: 22px;
        }
    }

    &.xl {
        font-size: 30px;

        @media (max-width: $mobileMaxWidth) {
            font-size: 28px;
        }
    }

    &.xxl {
        font-size: 34px;

        @media (max-width: $mobileMaxWidth) {
            font-size: 30px;
        }
    }

    &.bold {
        font-weight: 700;
    }

    &.medium {
        font-weight: 600;
    }

    &.regular {
        font-weight: 500;
    }

    &.thin {
        font-weight: 400;
    }

    &.center {
        text-align: center;
    }

    &.pointer {
        cursor: pointer;
    }
}

.notification-item-title {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
}

.notification-item-text {
    font-size: 12px;
    line-height: 14px;
}

carousel {
    z-index: unset !important;
}

*:focus {
    outline: none;
    box-shadow: none;
}

* {
    @media (max-width: $mobileMaxWidth) {
        cursor: unset !important;
    }
}

.success-snackbar {
    .mdc-snackbar__surface {
        background-color: $greenVeryVeryLight !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        border: 1px solid $green;

        .mdc-snackbar__label {
            font-size: 12px;
            font-family: $fontFamily;
            color: $green !important;
        }
    }
}

.error-snackbar {
    color: $black !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    .mdc-snackbar__surface {
        background-color: $pinkLight !important;
        display: flex !important;
        padding: 12px !important;
        align-items: center !important;
        justify-content: center !important;
        border: 1px solid $red;

        .mdc-snackbar__label {
            font-size: 16px;
            font-weight: 700;
            font-family: $fontFamily;
            color: $red !important;
        }
    }
}

.mat-mdc-dialog-surface {
    padding: 24px;
}

.mat-mdc-option {
    font-size: 12px;
}

.taxes-list-dialog {
    mat-dialog-container {
        height: 80vh;
        overflow: unset !important;
        padding: 12px 12px 12px 24px;
    }
}

.video-dialog {
    .mat-mdc-dialog-surface {
        overflow: hidden;
        padding: unset;
    }
}

.leaving-submission-confirmation-modal {
    span {
        font-size: 12px !important;
    }
}

.dialog-with-dropdown {
    .mat-mdc-dialog-surface {
        overflow: unset;
    }
}

.token-display-dialog {
    .mat-mdc-dialog-surface {
        padding: 32px;
        overflow: unset;
    }
}

.transparent-dialog {
    .mat-mdc-dialog-surface {
        background-color: transparent !important;
        box-shadow: unset !important;
        display: flex !important;
    }

    .mat-mdc-dialog-container {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .mdc-dialog__surface {
        display: flex;
        justify-content: center;
        border-radius: 24px !important;
    }

    .mdc-dialog__container {
        width: 100% !important;
    }
}

.pointer {
    cursor: pointer;
}

.cancel-delete-snackbar {
    background: $almostBlack !important;
    padding: 16px !important;
    color: $white;
}

app-application-view,
app-campaigns,
app-application-version-view,
app-type,
app-confirmation,
app-dashboard,
app-invoices,
app-transaction-details,
app-psv-form,
app-submission,
app-submission-ios,
app-products,
app-app-view-statistics,
app-apps-list,
app-secret-keys,
app-sandbox-management,
app-edit-subscription,
in-app-products,
in-app-subscriptions,
app-subscriptions,
app-edit-product,
app-history,
app-wallet,
app-wallet-transaction-details,
app-wallet-withdraw-success-screen,
app-wallet-withdraw-error-screen,
app-wallet-invoices-list,
app-transactions-pre-generated-csvs-list,
app-statistics-pre-generated-csvs-list,
app-form,
app-gift-card-requests-list,
app-checkout,
app-vat-policy,
app-metadata-update-form,
app-d2c,
app-account-application-metadata-update-form,
.back-container {
    display: flex;
    flex-direction: column;
    max-width: $pageMaxWidth;
    margin: auto;
}

app-notifications-page {
    display: flex;
    flex-direction: column;
    max-width: 650px;
    margin: auto;
}

app-checkout {
    height: 100vh;
}

app-breadcrumb {
    z-index: 9;
}

app-main-header-mobile {
    z-index: 10;
}

.mat-mdc-menu-item .mdc-list-item__primary-text {
    font-family: $fontFamily;
    font-size: 12px;
    font-weight: 600;
    color: $almostBlack;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"]) ~ .mdc-checkbox__background {
    border-color: $almostBlack !important;
    background-color: transparent;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
    border-color: $almostBlack !important;
}

.addAppRadio .mdc-form-field {
    width: 100%;
    align-items: flex-start;
}

.addAppRadio .mdc-label {
    padding: 16px 0;
    width: 100%;
}

.addAppRadio .mat-mdc-radio-button .mdc-radio {
    padding: calc((var(--mdc-radio-state-layer-size) - 8px) / 2);
}

.mdc-form-field > label {
    cursor: pointer;

    @media (max-width: $mobileMaxWidth) {
        padding-left: 0px !important;
    }
}

.mdc-tooltip {
    border-radius: 6px;
    font-size: 11px;
    max-width: 400px !important;

    .mdc-tooltip__surface {
        background: $orangeDark !important;
        word-break: break-word !important;
        overflow-wrap: break-word !important;
        max-width: 320px !important;
        color: white;
        font-weight: 600;
    }
}

.no-padding-dialog {
    .mdc-dialog__surface {
        position: relative;
        padding: 0;
        border-radius: 24px !important;
    }

    @media (max-width: 800px) {
        width: 90dvw !important;
    }
}

.add-app-dialog {
    .mat-mdc-dialog-surface {
        position: relative;
        background-color: $grayAlmostWhite !important;
        padding: 0;
        border-radius: 24px !important;
    }
}

.add-missing-files-dialog {
    .mdc-dialog__surface {
        position: relative;
        padding: 32px;
        border-radius: 24px !important;
        margin: 24px;
    }

    @media (max-width: 800px) {
        width: 90dvw !important;
    }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 24px !important;
}

.bottom-dialog {
    .mdc-dialog__surface {
        border-top-right-radius: 24px !important;
        border-top-left-radius: 24px !important;
        border-bottom-right-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
}

.selected-list .c-angle-down svg,
.selected-list .c-angle-up svg {
    fill: $orange !important;
}

.selected-list .c-btn {
    color: $almostBlack !important;
}

.carousel-container {
    padding: 2px;
}

@media only screen and (max-width: 430px) {
    .revenue-dist-title {
        font-size: 16px !important;
    }
}

@media only screen and (max-width: $mobileMaxWidth) {
    p {
        font-size: 12px;
    }
}

.draggable {
    cursor: move;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cdk-overlay-container {
    z-index: 99999;
}

.poppins-font {
    font-family: $fontFamily;
}

.carousel-arrow-next {
    background-image: url("/") !important;
    box-shadow: 0 2px 4px 0 $black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $orange;
    font-size: 40px;

    &::before {
        content: ">";
    }
}

.carousel-arrow-prev {
    background-image: url("/") !important;
    box-shadow: 0 2px 4px 0 $black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $orange;
    font-size: 40px;

    &::before {
        content: "<";
    }
}

.distribution-panel {
    max-height: 60vh;
}

.movable:not(.dragging) {
    transition: transform 300ms ease-in-out;
}

body {
    font-family: $fontFamily;
    -webkit-font-smoothing: antialiased;
}

ul,
li {
    list-style: none;
}

ul {
    &.notifications {
        list-style: unset;
        margin-top: 6px;
        margin-bottom: 20px;
        padding-left: 28px;

        li {
            list-style: unset;

            p {
                font-weight: 500;
            }
        }
    }
}

a {
    &.notifications {
        font-weight: 700;
        color: $orange;
        text-decoration: none;
        cursor: pointer;
    }
}

img {
    &.clickable {
        cursor: pointer;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
a,
b,
li,
td,
label,
th,
span {
    &.no-select {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &.white {
        color: $white;
    }

    &.lightGray {
        color: $grayLight;
    }

    &.darkGray {
        color: $grayDark;
    }

    &.blueishGray {
        color: $blueishGray;
    }

    &.lightPurple {
        color: $lightPurple;
    }

    &.veryLightGray {
        color: $grayVeryLight;
    }

    &.veryVeryLightGray {
        color: $grayAlmostWhite;
    }

    &.pink {
        color: $orange;
    }

    &.green {
        color: $greenLight;
    }

    &.darkGreen {
        color: $green;
    }

    &.orange {
        color: $orangeLight;
    }

    &.almostBlack {
        color: $almostBlack;
    }

    &.gray {
        color: $gray;
    }

    &.darkestGray {
        color: $darkestGray;
    }

    &.red {
        color: $red;
    }

    &.lightRed {
        color: $lightRed;
    }

    &.orange {
        color: $orange;
    }

    &.underlined {
        text-decoration: underline;
    }

    &.centered {
        text-align: center;
    }

    &.align-end {
        text-align: end;
    }

    &.fixed-min-height {
        min-height: 16px;
    }

    &.uppercase {
        text-transform: uppercase;
    }

    &.italic {
        font-style: italic;
    }

    &.clickable {
        cursor: pointer;
    }

    &.non-clickable {
        cursor: unset !important;
    }

    &.fontSpaceMono {
        font-family: $fontSpaceMono;
    }

    &.big {
        font-size: 18px;
    }

    &.defaultLineHeight {
        line-height: 1 !important;
    }

    &.link {
        color: $orange;
        text-decoration: underline;
        cursor: pointer;
        font-weight: bold;

        &.white {
            color: white !important;
        }
    }

    &.biggie {
        font-size: 16px;
        line-height: 2;
    }

    &.smaller {
        font-size: 15px;
        line-height: 1.8;
    }

    &.verySmall {
        font-size: 14px;
        line-height: 1.6;
    }

    &.veryVerySmall {
        font-size: 12px;
        line-height: 1.4;
    }

    &.veryVeryVerySmall {
        font-size: 11px;
        line-height: 1.3;
    }

    &.superTiny {
        font-size: 10px !important;
        line-height: 1;
    }

    &.info-text {
        font-size: 12px;
        font-style: italic;
        line-height: 1.5;
    }

    &.superTiny {
        font-size: 10px !important;
        line-height: 1.5;
    }

    &.small-info-text {
        font-size: 11px;
        font-style: italic;
        line-height: 1.5;
    }

    &.veryThin {
        font-weight: 300;
    }

    &.thin {
        font-weight: 400;
    }

    &.normal {
        font-weight: 500;
    }

    &.semiBold {
        font-weight: 600;
    }

    &.thick {
        font-weight: 700;
    }

    &.capitalize {
        text-transform: capitalize;
    }

    &.big-line-height {
        line-height: 2.5;
    }

    &.low-line-height {
        line-height: 1.4;
    }

    &.no-letter-spacing {
        letter-spacing: 0px !important;
    }

    &.very-low-line-height {
        line-height: 1.2;
    }

    &.page-title {
        margin-top: 24px;
    }
}

h1 {
    color: $orange;
    font-size: 36px;
    line-height: 1.5;
    letter-spacing: normal;
    font-weight: bold;
    text-align: start;
    margin: 0;

    @media (max-width: $mobileMaxWidth) {
        font-size: 34px;
    }
}

h2 {
    color: $almostBlack;
    font-size: 32px;
    line-height: 1.45;
    letter-spacing: normal;
    font-weight: bold;
    text-align: start;
    margin: 0;

    @media (max-width: $mobileMaxWidth) {
        font-size: 30px;
    }
}

h3 {
    color: $almostBlack;
    font-size: 24px;
    line-height: 1.4;
    letter-spacing: normal;
    font-weight: bold;
    text-align: start;
    margin: 0;

    @media (max-width: $mobileMaxWidth) {
        font-size: 22px;
    }
}

h4 {
    color: $almostBlack;
    font-size: 18px;
    line-height: 1.4;
    letter-spacing: normal;
    font-weight: bold;
    text-align: start;
    margin: 0;

    &.thin {
        font-weight: 500;
    }

    @media (max-width: $mobileMaxWidth) {
        font-size: 16px;
    }
}

h5 {
    color: $almostBlack;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: normal;
    font-weight: 600;
    text-align: start;
    margin: 0;

    &.thin {
        font-weight: 500;
    }

    @media (max-width: $mobileMaxWidth) {
        font-size: 14px;
    }
}

h6 {
    color: $almostBlack;
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: normal;
    font-weight: 600;
    text-align: start;
    margin: 0;
}

p,
td,
th {
    color: $almostBlack;
    font-size: 12px;
    line-height: 2.2;
    font-weight: 500;
    word-wrap: break-word;
    text-align: start;
}

.modal-main-container {
    margin: -64px 0 24px 0;

    .modal-illustration {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
        }

        aptd-image {
            width: 100%;
        }
    }

    .modal-loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 64px 0;
    }

    .modal-message-content {
        background-color: $white;
        width: 100%;
        height: 50%;
    }

    .modal-message-align {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            text-align: center;

            &.error {
                margin-top: 40px;
            }
        }

        .text {
            margin: 0 24px;
            text-align: center;

            a {
                font-size: $fontSizeSmall;
                font-weight: 500;
                color: $orange;
                text-decoration: none;
                cursor: pointer;
                margin-left: 4px;
            }

            &.error {
                margin-bottom: 56px;
            }
        }

        .link {
            color: $orange;
            cursor: pointer;
        }
    }

    .modal-buttons-footer {
        z-index: 9999;
        right: 0;
        bottom: 0;
        left: 0;

        .modal-buttons-footer-content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 24px 32px;

            &.center {
                justify-content: center;
            }

            &.column-mobile {
                @media (max-width: $tabletMaxWidth) {
                    flex-direction: column-reverse;
                    justify-content: center;

                    app-button {
                        margin: 8px 0;
                    }
                }
            }
        }

        .loading {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 15px 0;
        }
    }
}

.ng-tooltip {
    max-width: 30% !important;
    background-color: $almostBlack;
    color: $white;
    max-width: 200px !important;
    line-height: 1.25;
    font-weight: 500;
    word-wrap: break-word;
    font-size: 10px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
    padding: 12px;
    position: absolute;
    z-index: 1000;
    display: block;
    opacity: 0;
    -webkit-transition: opacity 300ms;
    -moz-transition: opacity 300ms;
    -o-transition: opacity 300ms;
    transition: opacity 300ms;
}

.ng-tooltip-show {
    opacity: 1;
}

.ng-tooltip::after {
    content: "";
    position: absolute;
}

.ng-tooltip-top::after {
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: #f5f7f9 transparent transparent transparent;
}

.ng-tooltip-bottom::after {
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-color: transparent transparent #f5f7f9 transparent;
}

.ng-tooltip-left::after {
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: transparent transparent transparent #f5f7f9;
}

.ng-tooltip-right::after {
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-color: transparent #f5f7f9 transparent transparent;
}

snack-bar-container.mat-snack-bar-container {
    background-color: $white;
    margin: 24px;
    max-width: 50vw;
    min-width: 344px;
    min-height: 48px;
    padding: 0;
}

.mandatory-step-collapsible-card-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    pointer-events: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    border-radius: 22px;
}

.back {
    margin: 0;
    cursor: pointer;
    line-height: 2.2 !important;
    display: flex;
    gap: 5px;
    align-items: center;

    label {
        color: $orange;
        font-size: 16px;
        cursor: pointer;
    }

    &.payment {
        display: none;
        line-height: 0 !important;
        margin-top: 8px;
    }
}

.mobile-table {
    display: flex;
    flex-direction: column;
    width: 100%;

    .entry + .entry {
        margin-top: 8px;
    }

    .entry {
        padding: 16px;
        border-radius: 12px;
        background-color: $white;

        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 4px;
            gap: 24px;

            .ellipsis {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            p {
                display: flex;
                align-items: center;
                gap: 8px;

                &.no-gap {
                    gap: unset;
                }

                img {
                    max-width: 20px;
                    max-height: 20px;
                }
            }

            .completed {
                color: $green;
            }

            .rejected {
                color: $red;
            }

            .pending {
                color: $orange;
            }

            .actions {
                display: flex;
                width: 100%;
                justify-content: center;
                gap: 16px;

                img {
                    max-width: 17px;
                    max-height: 17px;
                }
            }

            .actions-right-aligned {
                justify-content: flex-end;
            }

            .date-mobile {
                .calendar {
                    font-size: 12px;
                    margin: unset;
                    line-height: 16px;
                }

                .hour {
                    font-size: 10px;
                    margin: unset;
                    color: $grayDark;
                    line-height: 16px;
                }
            }

            .distributed {
                color: $greenLight;
            }

            .undistributed {
                color: $orangeLight !important;
            }

            .suspended {
                color: $red !important;
            }

            .archived {
                color: $grayDark !important;
            }

            .pending {
                color: $orangeLight !important;
            }
        }
    }
}

.mat-mdc-menu-content {
    padding: unset !important;
}

.big-desktop-version {
    @media (max-width: $tabletMaxWidth) {
        display: none !important;
    }
}

.big-mobile-version {
    @media (min-width: $desktopSmallMinWidth) {
        display: none !important;
    }
}

.desktop-version {
    @media (max-width: $mobileMaxWidth) {
        display: none !important;
    }
}

.tablet-version {
    @media (min-width: $desktopSmallMinWidth) {
        display: none !important;
    }
}

.mobile-version {
    @media (min-width: $largeDevicesMinWidth) {
        display: none !important;
    }
}

.intercom-messenger-frame,
div[class^="intercom-"] {
    transform-origin: center top !important;

    @media (min-width: $largeDevicesMinWidth) {
        top: 60px !important;
        right: 4px !important;
        bottom: unset !important;
    }
}
